import React from 'react'
import { Link } from 'gatsby'
import './Layout.css'
import { Cow } from '../../components/Cow'
import Bio from '../../components/Bio'

require('prismjs/themes/prism-okaidia.css')
require('prismjs/plugins/line-numbers/prism-line-numbers.css')

class Template extends React.Component {
  render() {
    const { location, children } = this.props

    const rootPath = `/`

    const Heading = location && location.pathname === rootPath ? 'h1' : 'h3'

    const header = (
      <div className="layout__container">
        <div className="layout__heading">
          <div className="logo__container">
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'inherit',
              }}
              to={'/'}
            >
              <Cow />
            </Link>
          </div>
          <Heading className="layout__heading header">
            <Link to={'/'}>Paul Cowan</Link>
          </Heading>
        </div>
        <Bio />
      </div>
    )

    return (
      <div className="pages__container">
        {header}
        {children}
      </div>
    )
  }
}

export default Template
