import React from 'react'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import profilePic from './profile-pic.jpeg'

import './Bio.css'

class Bio extends React.Component {
  render() {
    const { showProfile } = this.props

    return (
      <div className="bio__container">
        {showProfile && <img src={profilePic} alt={`Paul Cowan`} />}
        <p>
          <strong>
            <br />
            <a href="https://frontendx/">Frontend DX expert</a>
            <br />
            <strong>
              Take the <a href="https://twitter.com/dagda1">frontend healthcheck</a>
            </strong>{' '}
            <br />
            <a href="https://www.frontenddx.com/contact">Contact me</a> for frontend
            help.
          </strong>
        </p>
      </div>
    )
  }
}

export default Bio
